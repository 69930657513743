//store/modules/crudservices.js
import instance from '../../config/axios'
import Vue from 'vue'

export const getDataService = (url, params = {}) => {
  let loader = Vue.$loading.show()
  return instance.get(url, {params})
  .then((response)=>{
    if(response.status >= 500 || response.status >= 400) throw response

    loader.hide()
    return{
      data: response.data.data
    }
  }).catch((e) => {
    loader.hide()
    Vue.$toast.open({
      message: e.data.messages[0],
      type: 'error',
    })
  })
}

export const postDataService = (url, data) => {
  let loader = Vue.$loading.show()
  return instance.post(url, data)
    .then((response) => {
      let type = response.data.success ? 'success' : 'error'
      Vue.$toast.open({
        message: response.data.messages[0],
        type: type,
      })
      loader.hide()

      return {
        data : response.data
      }
    }).catch((e) => {
      loader.hide()
      Vue.$toast.open({
        message: e.data.messages[0],
        type: 'error',
      })
    })
}

export const putDataService = (url, data) => {
  let loader = Vue.$loading.show()
  return instance.post(url, data)
    .then((response) => {
      let type = response.data.success ? 'success' : 'error'
      Vue.$toast.open({
        message: response.data.messages[0],
        type: type,
      })
      loader.hide()

      return {
        data : response.data
      }
    }).catch((e) => {
      loader.hide()
      Vue.$toast.open({
        message: e.data.messages[0],
        type: 'error',
      })
    })
}

export const patchDataService = (url, data) => {
  let loader = Vue.$loading.show()
  return instance.post(url, data)
    .then((response) => {
      let type = response.data.success ? 'success' : 'error'
      Vue.$toast.open({
        message: response.data.messages[0],
        type: type,
      })
      loader.hide()

      return {
        data : response.data
      }
    }).catch((e) => {
      loader.hide()
      Vue.$toast.open({
        message: e.data.messages[0],
        type: 'error',
      })
    })
}

export const deleteDataService = (url) => {
  let loader = Vue.$loading.show()
  return instance.delete(url)
    .then((response) => {
      let type = response.data.success ? 'success' : 'error'
      Vue.$toast.open({
        message: response.data.messages[0],
        type: type,
      })
      loader.hide()

      return {
        data : response.data
      }
    }).catch((e) => {
      loader.hide()
      Vue.$toast.open({
        message: e.data.message,
        type: 'error',
      })
    })
}

export default {
  fetch: instance,
}