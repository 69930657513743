export const dateConfig = {
  basic: {
    wrap: true, 
    altFormat: 'j F Y',
    altInput: true,
    dateFormat: 'Y-m-d',  
  },
  noBackDate:{
    wrap: true, 
    altFormat: 'j F Y',
    altInput: true,
    dateFormat: 'Y-m-d',
    minDate: "today"
  }
}

export const formatCurrency = {
  locale: undefined,
  currency: 'USD',
  currencyDisplay: 'hidden',
  valueRange: undefined,
  precision: 0,
  autoDecimalDigits: false,
  exportValueAsInteger: false,
  autoSign: true,
  useGrouping: true
}