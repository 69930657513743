<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah Pegawai</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="6" class="mb-3">
                  <label for="full_name">Nama Lengkap<strong>*</strong></label>
                  <input type="text" class="form-control" id="full_name" v-model="form.full_name">
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="nip">NIP</label>
                  <input type="number" class="form-control" id="nip" v-model="form.nip">
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="email">Email<strong>*</strong></label>
                  <input type="email" class="form-control" id="email" v-model="form.email">  
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="jabatan">Jabatan<strong>*</strong></label>
                  <input type="text" class="form-control" id="jabatan" v-model="form.jabatan">
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="golongan">Golongan</label>
                  <v-select :options="jabatan" v-model="form.golongan" label="golongan" @input="golonganHandle" class="style-chooser"></v-select>
                  <!-- <input type="text" class="form-control" id="golongan" v-model="form.golongan"> -->
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="pangkat">Pangkat</label>
                  <input type="text" class="form-control" id="pangkat" v-model="form.pangkat" readonly>
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="phone">Kontak</label>
                  <input type="text" class="form-control" id="phone" v-model="form.phone">
                </b-col>
                <b-col md="6" class="mb-3">
                  <label for="tgl_lahir">Tgl. Lahir</label>
                  <flat-pickr class="form-control" :config="config" id="tgl_lahir" v-model="form.tgl_lahir"></flat-pickr>
                </b-col>
                <b-col md="12" class="form-group col-sm-6">
                  <label class="d-block">Status Pegawai:</label>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" v-model="form.status_pegawai" id="aktif" value="aktif" class="custom-control-input">
                    <label class="custom-control-label" for="aktif"> Aktif </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" v-model="form.status_pegawai" value="pensiun" id="pensiun" class="custom-control-input">
                    <label class="custom-control-label" for="pensiun"> Pensiun </label>
                  </div>
                </b-col>
                <b-col md="12" class="form-group col-sm-6">
                  <label class="d-block">Jenis Kelamin<strong>*</strong>:</label>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" v-model="form.jenis_kelamin" id="laki" value="Laki-laki" class="custom-control-input">
                    <label class="custom-control-label" for="laki"> Laki-Laki </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" v-model="form.jenis_kelamin" value="Perempuan" id="perempuan" class="custom-control-input">
                    <label class="custom-control-label" for="perempuan"> Perempuan </label>
                  </div>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="ss">Foto</label>
                  <div class="form-group">
                    <div class="custom-file">
                      <input type="file" ref="file" class="custom-file-input" id="foto" v-on:change="handleFileForm()">
                      <label class="custom-file-label" for="customFile">{{ label.file }}</label>
                    </div>
                  </div>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="address">Alamat</label>
                  <textarea class="form-control" v-model="form.address" id="address"></textarea>
                </b-col>
                <b-col md="12" class="form-group col-sm-6">
                  <label class="d-block">User Login:</label>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" v-model="form.pegawai_app" id="pegawai_app_ya" value="1" class="custom-control-input">
                    <label class="custom-control-label" for="pegawai_app_ya"> Ya </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" v-model="form.pegawai_app" value="0" id="pegawai_app_tidak" class="custom-control-input">
                    <label class="custom-control-label" for="pegawai_app_tidak"> Tidak </label>
                  </div>
                </b-col>
              </div>
              
              <div class="float-right">
                <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                <button type="submit" class="btn btn-primary" @click.prevent="formSave()">Simpan</button>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { postDataService } from '../../../store/modules/crudservices'
import { dateConfig } from '../../../_utils/form-config'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  created(){
    document.title = "Tambah Pegawai"
  },
  name:'PegawaiAdd',
  data() {
    return {
      config: dateConfig.basic,
      label: {
        file: 'Pilih Foto'
      },
      form:{
        nip: null,
        position_id: null,
        full_name: null,
        pangkat: null,
        golongan: null,
        email: null,
        tgl_lahir: null,
        jenis_kelamin: 'Laki-laki',
        phone: null,
        address: null,
        file: null,
        pegawai_app: 1,
        status_pegawai: 'aktif'
      },
      jabatan:[
        {
          'golongan': 'I/a',
          'pangkat': 'Juru Muda'
        },
        {
          'golongan': 'I/b',
          'pangkat': 'Juru Muda Tingkat I'
        },
        {
          'golongan': 'I/c',
          'pangkat': 'Juru'
        },
        {
          'golongan': 'I/d',
          'pangkat': 'Juru Tingkat I'
        },
        {
          'golongan': 'II/a',
          'pangkat': 'Pengatur Muda'
        },
        {
          'golongan': 'II/b',
          'pangkat': 'Pengatur Muda Tingkat I'
        },
        {
          'golongan': 'II/c',
          'pangkat': 'Pengatur'
        },
        {
          'golongan': 'II/d',
          'pangkat': 'Pengatur Tingkat I'
        },
        {
          'golongan': 'III/a',
          'pangkat': 'Penata Muda'
        },
        {
          'golongan': 'III/b',
          'pangkat': 'Penata Muda Tingkat I'
        },
        {
          'golongan': 'III/c',
          'pangkat': 'Penata'
        },
        {
          'golongan': 'III/d',
          'pangkat': 'Penata Tingkat I'
        },
        {
          'golongan': 'IV/a',
          'pangkat': 'Pembina'
        },
        {
          'golongan': 'IV/b',
          'pangkat': 'Pembina Tingkat I'
        },
        {
          'golongan': 'IV/c',
          'pangkat': 'Pembina Utama Muda'
        },
        {
          'golongan': 'IV/d',
          'pangkat': 'Pembina Utama Madya'
        },
        {
          'golongan': 'IV/e',
          'pangkat': 'Pembina Utama'
        }
      ]
    }
  },
  components:{
    flatPickr
  },
  methods: {
    formSave(){
      const _formData = new FormData()
      const form = this.form
      _formData.append('nip', form.nip)
      _formData.append('full_name', form.full_name)
      _formData.append('jabatan', form.jabatan)
      _formData.append('email', form.email)
      _formData.append('pangkat', form.pangkat)
      _formData.append('golongan', form.golongan)
      _formData.append('tgl_lahir', form.tgl_lahir)
      _formData.append('jenis_kelamin', form.jenis_kelamin)
      _formData.append('pegawai_app', form.pegawai_app)
      _formData.append('phone', form.phone)
      _formData.append('address', form.address)
      _formData.append('status_pegawai', form.status_pegawai)
      _formData.append('file', form.file)
      // _formData.append('perms', JSON.stringify(this.perms))
      
      postDataService('/pegawai', _formData).then(response => {
        if(response.data.success) this.$router.push('/master/pegawai')
      })
    },
    formCancel(){
      this.$router.push('/master/pegawai') 
    },
    handleFileForm(){
      let file = this.$refs.file.files[0]
      this.label.file = file.name
      this.form.file = file
    },
    golonganHandle(props){
      this.form.golongan = props != null ? props.golongan : null
      this.form.pangkat = props != null ? props.pangkat : null
    }
  },
  mounted() {
    // this.getJabatan()
  }
}
</script>